import { getAccountMenuContent } from '@/components/NavMenu';
import type { VNode } from 'vue';
import { useRoute } from 'vue-router';
import { NuxtLink } from '#components';
import './AccountPageMenu.css';

export default defineComponent({
  name: 'AccountPageMenu',
  setup() {
    const route = useRoute();

    const { currentCustomer } = useCurrentCustomer();
    const menu = computed(() => getAccountMenuContent(currentCustomer.value));
    return { route, menu };
  },
  render(): VNode {
    return (
      <aside>
        <h1 class="font-bold">{this.$t('account_page.heading')}</h1>
        <ul class="account-menu-links">
          {this.menu.menuItems.map((item) => (
            <li key={item.name}>
              <NuxtLink class="account-menu-link" to={{ name: item.name }}>
                <item.icon class="icon" />
                {item.label}
              </NuxtLink>
            </li>
          ))}
        </ul>
        <ul class="account-menu-links">
          {this.menu.additionalMenuItems.map((item) => (
            <li key={item.name}>
              <NuxtLink
                class="account-menu-link flex justify-between"
                to={{ name: item.name }}
                active-class="bg-rb-green text-white"
              >
                <span class={{ 'notification-dot': item.notification }}>
                  {item.label}
                </span>
                <item.icon class="icon" />
              </NuxtLink>
            </li>
          ))}
        </ul>
      </aside>
    );
  },
});
